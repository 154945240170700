const _none = "none"
const _spin = "spin 1s linear infinite"
const _ping = "ping 1s cubic-bezier(0, 0, 0.2, 1) infinite"
const _pulse = "pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite"
const _bounce = "bounce 1s infinite"
const _slideDownAndFade = "slideDownAndFade 200ms cubic-bezier(0.16, 1, 0.3, 1)"
const _slideLeftAndFade = "slideLeftAndFade 200ms cubic-bezier(0.16, 1, 0.3, 1)"
const _slideUpAndFade = "slideUpAndFade 200ms cubic-bezier(0.16, 1, 0.3, 1)"
const _slideRightAndFade = "slideRightAndFade 200ms cubic-bezier(0.16, 1, 0.3, 1)"
const config = { "none": _none, "spin": _spin, "ping": _ping, "pulse": _pulse, "bounce": _bounce, "slideDownAndFade": _slideDownAndFade, "slideLeftAndFade": _slideLeftAndFade, "slideUpAndFade": _slideUpAndFade, "slideRightAndFade": _slideRightAndFade,  }
export { config as default, _none, _spin, _ping, _pulse, _bounce, _slideDownAndFade, _slideLeftAndFade, _slideUpAndFade, _slideRightAndFade }